<template>
  <!-- 企讯云+ -->
  <div class="">
    <head-nav></head-nav>
    <!--  头部导航   -->
    <div>
      <header class="index1">
        <!-- <div class="backgroundImg">
          <div class="backgroundImgAll" style=""></div>
        </div> -->
          <div class="ceshitupian" style="width:100%;display: flex;justify-content: center;">
              <img class="imgceshi" src="../assets/07产品-企讯云+/banner@2x.jpg" style="width:1920px;height:100%;object-fit:fill;">
          </div>
      </header>
    </div>

    <!-- <div class="systemTools" style="">
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">回拨系统</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>

    </div> -->

    <div class="ceshitupian" style="width:100%;display: flex;justify-content: center;">
        <img class="imgceshi" src="../assets/07产品-企讯云+/huibo.jpg" style="width:1920px;height:100%;object-fit:fill;">
    </div>
    <footer-top></footer-top>
    <Footer></Footer>
  </div>
</template>

<script>
import HeadNav from "@/components/headNav.vue";
import footerTop from "@/components/footerTop.vue";
import Footer from "@/components/footer.vue";
import { Swiper, Navigation, Pagination, Autoplay, Scrollbar } from "swiper";
Swiper.use([Navigation, Pagination, Autoplay, Scrollbar]);
import eventBus from '../config/eventBus.js'
export default {
  name: "",
  components: {
    HeadNav,
    footerTop,
    Footer,
  },
  data() {
    return {
      industryList: [
        {
          id: 0,
          titel: "应用领域案例",
        },
        {
          id: 1,
          titel: "企业服务案例",
        },
        {
          id: 2,
          titel: "零售行业案例",
        },
        {
          id: 3,
          titel: "教育行业案例",
        },
        {
          id: 4,
          titel: "装修行业案例",
        },
        {
          id: 5,
          titel: "地产公司案例",
        },
        {
          id: 6,
          titel: "金融行业案例",
        },
        {
          id: 7,
          titel: "医美行业案例",
        },
        {
          id: 8,
          titel: "休闲娱乐行业案例",
        },
      ],
      flag2: 0,
      Myswiper2: null,
    };
  },
  mounted() {
    var that = this;
    // this.timeChange();
    that.Myswiper2 = new Swiper(".swiper-container2", {

      direction: "vertical",
      navigation: false,
      on: {
        transitionEnd: function () {
          that.flag2 = this.activeIndex;
        },
      },
    });
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  methods: {
    changesize(){
      eventBus.$emit('add',true)
    },
    changeIndustry(e) {
      // console.log(e,'654')
      this.flag2 = e;
      this.Myswiper2.slideTo(e, 1000, false);
    },
  },
};
</script>

<style src="@/style/QiXunCloud+.css" scoped></style>
<style lang="scss" scoped>
.swiper-container2 {
  width: 960px;
  height: 600px;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
